import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";


const initialBanner= {
    id: null,
    titulo: null,
    enlace: null,
    imagen: null,
    orden: null
};


const initialState = {
    allBanners: [],
    banner: initialBanner
}

const allBanners = (state = initialState.allBanners, action) => {
    switch (action.type) {
        case actionTypes.GET_BANNERS:
            return action.banners;
        default:
            return state;
    }
};

const bannerById = (state = initialState.banner, action) => {
    switch (action.type) {
        case actionTypes.GET_BANNER_BY_ID:
            return action.banner;
        default:
            return state;
    }
};

const reducer = combineReducers({
    allBanners,
    bannerById
});

export default reducer;