import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

const initialState = {
    allSessions: []
}

const allSessions = (state = initialState.allSessions, action) => {
    switch (action.type) {
        case actionTypes.GET_SESSIONS:
            return action.sessions;
        default:
            return state;
    }
};

const reducer = combineReducers({
    allSessions,
});

export default reducer;