import * as actionTypes from "./actionTypes";
import * as profileService from "../../api/services/profileService";


export const getUserDataCompleted = (data) => ({
    type: actionTypes.GET_USER_DATA,
    data: data,
});

export const getUserPointsCompleted = (data) => ({
    type: actionTypes.GET_USER_POINTS,
    data: data,
});

export const getUserTicketsCompleted = (data) => ({
    type: actionTypes.GET_USER_TICKETS,
    data: data,
});

export const getUserSubscriptionsCompleted = (data) => ({
    type: actionTypes.GET_USER_SUBSCRIPTIONS,
    data: data,
});

export const changeUserPinCompleted = (data) => ({
    type: actionTypes.CHANGE_USER_PIN,
    data: data,
});

export const getUserData = (uuid, cinema, onSuccess, onError) => (dispatch) => {
    profileService.getUserData(uuid, cinema, response => {
        dispatch(getUserDataCompleted(response));
        onSuccess(response)
    }, onError);
};

export const editUserData = (dataObj, onSuccess, onError) => (dispatch) => {
    profileService.editUserData(dataObj, response => {
        onSuccess(response)
    }, onError)
}

export const getUserPoints = (uuid, cinema, onSuccess) => (dispatch) => {
    profileService.getUserPoints(uuid, cinema, response => {
        dispatch(getUserPointsCompleted(response));
        onSuccess(response)
    });
};

export const getUserTickets = (uuid, cinema, onSuccess) => (dispatch) => {
    profileService.getUserTickets(uuid, cinema, response => {
        dispatch(getUserTicketsCompleted(response));
        onSuccess(response)
    });
};

export const getUserSubscriptions = (uuid, cinema, onSuccess) => (dispatch) => {
    profileService.getUserSubscriptions(uuid, cinema, response => {
        dispatch(getUserSubscriptionsCompleted(response));
        onSuccess(response)
    });
};

export const changeUserPin = (uuid, cinema, currentPin, newPin, onSuccess, onError) => (dispatch) => {
    profileService.changeUserPin(uuid, cinema, currentPin, newPin, response => {
        dispatch(changeUserPinCompleted(response));
        onSuccess(response)
    }, onError);
};

export const recoverUserCard = (uuid, cinema, onSuccess, onError) => (dispatch) => {
    profileService.recoverUserCard(uuid, cinema, response => {
        onSuccess(response)
    }, onError);
}

export const profilePurchase = (uuid, cinema, type, idbs, onSuccess, onError) => (dispatch) => {
    profileService.profilePurchase(uuid, cinema, type, idbs, onSuccess, onError)
}