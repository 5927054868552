import * as userService from "../../api/services/userService";
import * as actionTypes from "./actionTypes";

export const logInCompleted = (data) => ({
    type: actionTypes.LOGIN_SUCCESS,
    userData: data,
});

export const logOutCompleted = (data) => ({
    type: actionTypes.LOGOUT_SUCCESS,
    userData: data,
});

export const logIn = (formData, onSuccess, onError) => (dispatch) => {
    userService.logIn(
        formData,
        (response) => {
            dispatch(logInCompleted(response));
            response.cinema = formData.cine
            onSuccess(response);
        },
        (response) => onError(response)
    );
};

export const recoverPassword = (formData, onSuccess, onError) => (dispatch) => {
    userService.recoverPassword(
        formData,
        onSuccess,
        onError
    );
};

export const logOut = (uuid, cine, onSuccess, onError) => (dispatch) => {
    userService.logOut(
        uuid, cine,
        (response) => {
            dispatch(logOutCompleted(response));
            onSuccess(response);
        },
        (response) => onError(response)
    );
}

export const registerUser = (formData, onSuccess, onError) => (dispatch) => {
    userService.registerUser(formData, onSuccess, onError, (response) => {
        onSuccess(response);
    });
};
