import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";


const initialFilm = {
    id: null,
    idGenero: null,
    titulo: null,
    estreno: null,
    director: null,
    reparto: null,
    pais: null,
    productora: null,
    duracion: null,
    calificacionRe: null,
    calificacionGa: null,
    sinopsis: null,
    trailer: null,
    reproductor: null,
    imagenGrande: null,
    imagenCabecera: null,
    idImdb: null,
    slug: null,
    idGalicine: null,
    tipoContenido: null,
    proximo: null
};

const initialCinemaFilm = {
    idCine: null,
    idPelicula: null,
    dolbyyatmos: null,
    promo: null,
    textoPromo: null,
    estado: null
};

const initialState = {
    allFilms: [],
    allCalifications: [],
    film: initialFilm,
    cinemaFilm: initialCinemaFilm,
    carteleraFilms: []
}

const allCalifications = (state = initialState.allCalifications, action) => {
    switch (action.type) {
        case actionTypes.GET_CALIFICATION_BY_FILM:
            return action.calification;
        default:
            return state;
    }
};

const allFilms = (state = initialState.allFilms, action) => {
    switch (action.type) {
        case actionTypes.GET_FILMS:
            return action.films;
        default:
            return state;
    }
};

const carteleraFilmsByCinema = (state = initialState.carteleraFilms, action) => {
    switch (action.type) {
        case actionTypes.GET_CARTELERA_FILMS_BY_CINEMA:
            return action.films;
        default:
            return state;
    }
};

const filmById = (state = initialState.film, action) => {
    switch (action.type) {
        case actionTypes.GET_FILM_BY_ID:
            return action.film;
        default:
            return state;
    }
};

const filmBySlug = (state = initialState.film, action) => {
    switch (action.type) {
        case actionTypes.GET_FILM_BY_SLUG:
            return action.film;
        default:
            return state;
    }
};

const filmByCinema = (state = initialState.cinemaFilm, action) => {
    switch (action.type) {
        case actionTypes.GET_FILM_BY_CINEMA:
            return action.cinemaFilm;
        default:
            return state;
    }
};

const filmByFilm = (state = initialState.cinemaFilm, action) => {
    switch (action.type) {
        case actionTypes.GET_FILM_BY_FILM:
            return action.cinemaFilm;
        default:
            return state;
    }
};

const reducer = combineReducers({
    allFilms,
    allCalifications,
    carteleraFilmsByCinema,
    filmById,
    filmBySlug,
    filmByCinema,
    filmByFilm
});

export default reducer;