import React, { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { loadReCaptcha } from 'react-recaptcha-v3';

import "../styles/global.less";

import { getCookieAnalytics } from "../../common/utils/cookiesUtils";
import ReactGA from 'react-ga4';

const Header = lazy(() => import('./Header'));
const Body = lazy(() => import('./Body'));
const Footer = lazy(() => import('./Footer'));
const CookieManager = lazy(() => import('./../../..//design/organisms/cookies/CookiesManager'));

function App() {
    const [widthScreen, setWidthScreen] = useState(window.innerWidth);

    const handleResize = () => {
        setWidthScreen(window.innerWidth);
    };
    useEffect(() => {
        if(getCookieAnalytics() && getCookieAnalytics()!="false") {
            window.dataLayer.push({event: 'pageview', page: {url: window.location.pathname + window.location.search, title: "Body"}});
            //ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
        }
        loadReCaptcha("6LcpY8skAAAAAGrt13jM7dn1CRZnDirDTpo3sxQ_", ()=>{});
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Suspense>
            <BrowserRouter>
                <Header widthScreen={widthScreen}/>
                <Body widthScreen={widthScreen}/>
                <Footer widthScreen={widthScreen}/>
            </BrowserRouter>
            <CookieManager/>
        </Suspense>
    );
}

export default App;
