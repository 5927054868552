import { config, appFetch } from "../appFetch";

export const getUserData = (uuid, cinema, onSuccess, onError) => appFetch(`https://api.galicine.com.es/api/user/function::getSocioRE||cine::${cinema}||uuid::${uuid}`, config("GET"), onSuccess, onError);
export const getUserPoints = (uuid, cinema, onSuccess) => appFetch(`https://api.galicine.com.es/api/user/function::mov_pun||cine::${cinema}||uuid::${uuid}`, config("GET"), onSuccess);
export const getUserTickets = (uuid, cinema, onSuccess) => appFetch(`https://api.galicine.com.es/api/user/function::mov_bon||cine::${cinema}||uuid::${uuid}`, config("GET"), onSuccess);
export const getUserSubscriptions = (uuid, cinema, onSuccess) => appFetch(`https://api.galicine.com.es/api/user/function::mov_sus||cine::${cinema}||uuid::${uuid}`, config("GET"), onSuccess);

export const changeUserPin = (uuid, cinema, currentPin, newPin, onSuccess, onError) => appFetch(`https://api.galicine.com.es/api/user/function::modpinre`, config("POST", {
    uuid: uuid,
    pin: currentPin,
    nuevopin: newPin,
    cine: cinema
}), onSuccess, onError);

export const editUserData = (dataObj, onSuccess, onError) => appFetch(`https://api.galicine.com.es/api/user/function::modsociore`, config("PUT", dataObj), onSuccess, onError);

export const recoverUserCard = (uuid, cinema, onSuccess, onError) => appFetch(`https://api.galicine.com.es/api/user/function::reenviartarjetare`, config("POST", {
    uuid: uuid,
    cine: cinema
}), onSuccess, onError);

export const profilePurchase = (uuid, cinema, type, idbs, onSuccess, onError) => appFetch(`https://api.galicine.com.es/api/compra/function::resumenbs`, config("POST", {
    uuid: uuid,
    cine: cinema,
    tipo: type,
    idbs: idbs
}), onSuccess, onError);