/* eslint-disable indent */
import NetworkError from './NetworkError';
import { getCookie } from "../modules/common/utils/cookiesUtils.js";

const SERVICE_TOKEN_NAME = 'serviceToken';

let networkErrorCallback;
let reauthenticationCallback;

const isJson = (response) => {
  const contentType = response.headers.get('content-type');
  return contentType && contentType.indexOf('application/json') !== -1;
};

const handleOkResponse = (response, onSuccess) => {
  if (!response.ok) {
    return false;
  }
  if (!onSuccess) {
    return true;
  }
  if (response.status === 204) {
    onSuccess();
    return true;
  }
  if (isJson(response)) {
    response.json().then((payload) => onSuccess(payload));
  } else {
    onSuccess();
  }

  return true;
};

const handle4xxResponse = (response, onErrors) => {
  if (response.status < 400 || response.status >= 500) {
    return false;
  }
  if (response.status === 401 && reauthenticationCallback) {
    reauthenticationCallback();
    return true;
  }
  if (!isJson(response)) {
    throw new NetworkError();
  }
  if (onErrors) {
    response.json().then((payload) => {
      if (payload.code || payload.fieldErrors) {
        onErrors(payload);
      } else {
        onErrors('Fatal Error');
      }
    });
  }

  return true;
};

const handleResponse = (response, onSuccess, onErrors) => {
  if (handleOkResponse(response, onSuccess)) {
    return;
  }
  if (handle4xxResponse(response, onErrors)) {
    return;
  }
  throw new NetworkError();
};

export const init = (callback) => (networkErrorCallback = callback);

export const setReauthenticationCallback = (callback) =>
  (reauthenticationCallback = callback);


export const getServiceToken = () => getCookie(SERVICE_TOKEN_NAME);

export const config = (method, body) => {
  const config = {};
  config.method = method;
  if (body) {
    if (body instanceof FormData) {
      config.body = body;
    } else {
      config.headers = {'Content-Type': 'application/json'};
      config.body = JSON.stringify(body);
    }
  }
  const serviceToken = getServiceToken();
  if (serviceToken) {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${serviceToken}`;
    } else {
      config.headers = {Authorization: `Bearer ${serviceToken}`};
    }
  }
  
  return config;
};

export const appFetch = (path, options, onSuccess, onErrors) => {
  fetch(path, options)
    .then((response) => {
      try {
        handleResponse(response, onSuccess, onErrors);
      } catch (error) {
        onErrors(error);
      }
    })
    .catch(networkErrorCallback);
};

export const appFetchFile = (path, options, onSuccess, onErrors) => {
  const formData = new FormData();
  formData.append('document', options.file, options.file.name);
  formData.append('data', JSON.stringify(options.data));
  formData.append('token', options.token);
  fetch(path, {
    method: 'POST',
    body: formData
  }).then((response) => {
    try {
      handleResponse(response, onSuccess, onErrors);
    } catch (error) {
      onErrors(error);
    }
  }).catch(networkErrorCallback);
}
