import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

const initialGenre = {
    id: null,
    titulo: null,
    slug: null
};


const initialState = {
    allGenres: [],
    genre: initialGenre
}

const allGenres = (state = initialState.allGenres, action) => {
    switch (action.type) {
        case actionTypes.GET_GENRES:
            return action.genres;
        default:
            return state;
    }
};

const genreById = (state = initialState.genre, action) => {
    switch (action.type) {
        case actionTypes.GET_GENRE_BY_ID:
            return action.genre;
        default:
            return state;
    }
};

const genreBySlug = (state = initialState.genre, action) => {
    switch (action.type) {
        case actionTypes.GET_GENRE_BY_SLUG:
            return action.genre;
        default:
            return state;
    }
};

const reducer = combineReducers({
    allGenres,
    genreById,
    genreBySlug
});

export default reducer;