import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

import { getCookieIdLogin, getCookieNameLogin } from "../common/utils/cookiesUtils";

const idLogin = getCookieIdLogin();
const nameLogin = getCookieNameLogin();

const loggedData = {
    isLoggedIn: idLogin ? true : false,
    uuid: idLogin,
    name: nameLogin
};

const userData = {
    idSocio: "",
    idCine: "",
    nombreCine: "",
    nombre: "",
    apellidos: "",
    fechaNac: "",
    direccion: "",
    codPostal: "",
    poblacion: "",
    telefono: "",
    email: "",
};

const initialState = {
    loggedData: loggedData,
    userData: userData,
};

const login = (state = initialState.loggedData, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            return {
                isLoggedIn: true,
                uuid: action.userData.UUID,
                name: action.userData.nombre
            };
        case actionTypes.LOGOUT_SUCCESS:
            return {
                isLoggedIn: false,
                uuid: "",
                name: ""
            };
        default:
            return state;
    }
}


const reducer = combineReducers({
    login
});

export default reducer;