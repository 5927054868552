import TagManager from 'react-gtm-module';

const COOKIE_UUID_LOGIN = "uuidLogin";
const COOKIE_NAME_LOGIN = "nameLogin";
const COOKIE_CINEMA_LOGIN = "cinemaLogin";
const COOKIE_ROLE_LOGIN = "roleLogin";
const COOKIE_FILM_SELECTED = "cinemaOfFilmSelected";
const COOKIE_ANALYTICS = "analyticsAccepted";
const COOKIE_UUID_MAP = "uuidMap";

const tagManagerArgs = {
  gtmId: 'GTM-W32M2G6'
}

export const setCookieInfo = (uuid, name, role, time) => {
    setCookie(COOKIE_UUID_LOGIN, uuid, time);
    setCookie(COOKIE_NAME_LOGIN, name, time);
    setCookie(COOKIE_ROLE_LOGIN, role, time);
};

export const setCookieInfoLogin = (uuid, name, cinema, role, time) => {
    setCookie(COOKIE_UUID_LOGIN, uuid, time);
    setCookie(COOKIE_NAME_LOGIN, name, time);
    setCookie(COOKIE_CINEMA_LOGIN, cinema, time);
    setCookie(COOKIE_ROLE_LOGIN, role, time);
};

export const setCookieFilmSelected = (cinema, time) => {
    setCookie(COOKIE_FILM_SELECTED, cinema, time);
};

export const setCookieMapUUID = (uuid, time) => {
    setCookie(COOKIE_UUID_MAP, uuid, time);
};

export const getCookieIdLogin = () => getCookie(COOKIE_UUID_LOGIN);
export const getCookieNameLogin = () => getCookie(COOKIE_NAME_LOGIN);
export const getCookieCinemaLogin = () => getCookie(COOKIE_CINEMA_LOGIN);
export const getCookieRoleLogin = () => getCookie(COOKIE_ROLE_LOGIN);

export const getCookieFilmSelected = () => getCookie(COOKIE_FILM_SELECTED);

export const getCookieAnalytics = () => getCookie(COOKIE_ANALYTICS);

export const getCookieMapUUID = () => getCookie(COOKIE_UUID_MAP);

export const removeCookieInfo = () => {
    setCookie(COOKIE_UUID_LOGIN, "", -1);
    setCookie(COOKIE_NAME_LOGIN, "", -1);
    setCookie(COOKIE_ROLE_LOGIN, "", -1);
};

export const removeCookieInfoLogin = () => {
    setCookie(COOKIE_UUID_LOGIN, "", -1);
    setCookie(COOKIE_NAME_LOGIN, "", -1);
    setCookie(COOKIE_CINEMA_LOGIN, "", -1);
    setCookie(COOKIE_ROLE_LOGIN, "", -1);
};

export const removeCookieFilmSelected = () => {
    setCookie(COOKIE_FILM_SELECTED, "", -1);
};

export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie =
        cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Strict";
};

export const getCookie = (cname) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const manageCookies = (cookies) => {
    /*if(cookies.analytics){
        TagManager.initialize(tagManagerArgs);
        //ReactGA.initialize('G-WGNRC0NQ4R');
        //ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
    }*/
    //setCookie("analyticsAccepted", cookies.analytics ? true : false, 30000);
    //setCookie("cookiesAccepted", true, 30000);
    setCookie("analyticsAccepted", cookies.analytics ? true : false, 30000);
    setCookie("preferencesAccepted", cookies.preferencies ? true : false, 30000);
    setCookie("marketingAccepted", cookies.marketing ? true : false, 30000);
    setCookie("cookiesAccepted", true, 30000);

    window.gtag('consent', 'update', {
      'ad_storage': getCookie("marketingAccepted") && getCookie("marketingAccepted") !="false" ? 'granted' : 'denied',
      'ad_user_data': getCookie("marketingAccepted") && getCookie("marketingAccepted") !="false" ? 'granted' : 'denied',
      'ad_personalization': getCookie("marketingAccepted") && getCookie("marketingAccepted") !="false" ? 'granted' : 'denied',
      'analytics_storage': getCookie("analyticsAccepted") && getCookie("analyticsAccepted") !="false" ? 'granted' : 'denied',
      'personalization_storage': getCookie("preferencesAccepted") && getCookie("preferencesAccepted") !="false" ? 'granted' : 'denied',
      'functionality_storage': getCookie("preferencesAccepted") && getCookie("preferencesAccepted") !="false" ? 'granted' : 'denied',
      'security_storage': 'granted'
    });
};
