import { combineReducers } from 'redux';

import app from '../modules/app';
import sliders from '../modules/sliders';
import blogs from '../modules/blogs';
import promotions from '../modules/promotions';
import films from '../modules/films';
import cinemas from '../modules/cinemas';
import genres from '../modules/genres';
import headers from '../modules/headers'
import banners from '../modules/banners';
import sessions from '../modules/sessions';
import maps from '../modules/maps';
import users from '../modules/users';
import cookies from '../modules/cookies';
import profile from '../modules/profile';
import nextreleases from '../modules/nextreleases';

const reducer = combineReducers({
  app: app.reducer,
  sliders: sliders.reducer,
  blogs: blogs.reducer,
  promotions: promotions.reducer,
  films: films.reducer,
  cinemas: cinemas.reducer,
  genres: genres.reducer,
  headers: headers.reducer,
  banners: banners.reducer,
  sessions: sessions.reducer,
  maps: maps.reducer,
  users: users.reducer,
  cookies: cookies.reducer,
  profile: profile.reducer,
  nextreleases: nextreleases.reducer
});

export default reducer;
