import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

const initialCinema = {
    id: null,
    idCategoria: null,
    titulo: null,
    entradilla: null,
    cuerpo: null,
    fechaAlta: null,
    fechaModificacion: null,
    imagenDestacada: null,
    slug: null
};


const initialState = {
    allCinemas: [],
    cinema: initialCinema,
    idCinema: 0, 
    cinemaDolby: []
}

const allCinemas = (state = initialState.allCinemas, action) => {
    switch (action.type) {
        case actionTypes.GET_CINEMAS:
            return action.cinemas;
        default:
            return state;
    }
};

const cinemaById = (state = initialState.cinema, action) => {
    switch (action.type) {
        case actionTypes.GET_CINEMA_BY_ID:
            return action.cinema;
        default:
            return state;
    }
};

const cinemaBySlug = (state = initialState.cinema, action) => {
    switch (action.type) {
        case actionTypes.GET_CINEMA_BY_SLUG:
            return action.cinema;
        default:
            return state;
    }
};

const cinemasWithDolby = (state = initialState.cinemaDolby, action) => {
    switch (action.type) {
        case actionTypes.GET_CINEMAS_WITH_DOLBY:
            return action.cinemas;
        default:
            return state;
    }
};

const cinemaGalicinos = (state = initialState.idCinema, action) => {
    switch (action.type) {
        case actionTypes.GET_CINEMA_GALICINOS:
            return action.idCinema;
        default:
            return state;
    }
};

const reducer = combineReducers({
    allCinemas,
    cinemaById,
    cinemaBySlug,
    cinemasWithDolby,
    cinemaGalicinos
});

export default reducer;