import * as actionType from "./actionTypes";
import * as formContact from "../../api/services/formsContact";

export const getTokenRecaptcha = (data) => ({
  type: actionType.GET_TOKEN_RECAPTCHA,
  tokenRecaptcha: data
});

export const sendReservaColegio = (formData, onSuccess, onError) => () => {
  formContact.sendReservaColegios(formData, onSuccess, onError, (response)=> { onSuccess(response)})
};

export const sendTrabajaConNosotros = (formData, onSuccess, onError) => () => {
  formContact.sendTrabajaConNosotros(formData, onSuccess, onError, (response)=> { onSuccess(response)})
};

export const sendMailContacto = (formData, onSuccess, onError) => () => {
  formContact.sendMailContactos(formData, onSuccess, onError, 
  (response) => { 
    onSuccess(response)
  }, 
  (response) => {onError(response)}
  )
};
