import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";


const initialNextRelease = {

    //CHANGE !!!!!!!!!!!!



    id: null,
    idGenero: null,
    titulo: null,
    estreno: null,
    director: null,
    reparto: null,
    pais: null,
    productora: null,
    duracion: null,
    calificacionRe: null,
    calificacionGa: null,
    sinopsis: null,
    trailer: null,
    reproductor: null,
    imagenGrande: null,
    imagenCabecera: null,
    idImdb: null,
    slug: null,
    idGalicine: null,
    tipoContenido: null,
    proximo: null
};

const initialState = {
    allNextReleases: [],
    nextRelease: initialNextRelease
}

const allNextReleases = (state = initialState.allNextReleases, action) => {
    switch (action.type) {
        case actionTypes.GET_NEXT_RELEASES:
            return action.nextReleases;
        default:
            return state;
    }
};

const nextReleaseById = (state = initialState.nextRelease, action) => {
    switch (action.type) {
        case actionTypes.GET_NEXT_RELEASE_BY_ID:
            return action.nextRelease;
        default:
            return state;
    }
};

const nextReleaseBySlug = (state = initialState.nextRelease, action) => {
    switch (action.type) {
        case actionTypes.GET_NEXT_RELEASE_BY_SLUG:
            return action.nextRelease;
        default:
            return state;
    }
};

const reducer = combineReducers({
    allNextReleases,
    nextReleaseById,
    nextReleaseBySlug
});

export default reducer;