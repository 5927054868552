import * as actionTypes from "./actionTypes";
import * as sessionService from "../../api/services/sessionServices";

export const getAllSessionsCompleted = (data) => ({
    type: actionTypes.GET_SESSIONS,
    sessions: data,
});

export const getAllSessions = (cinema, uuid, film, onSuccess) => (dispatch) => {
    sessionService.getSessions(cinema, uuid, film, (response) => {
        dispatch(getAllSessionsCompleted(response));
        onSuccess(response);
    });
};