import * as actionTypes from "./actionTypes";
import * as sliderService from "../../api/services/sliderService";


export const getHomeSlidersAction = (homeSlidersData) => ({
  type: actionTypes.GET_HOME_SLIDERS,
  payload: homeSlidersData,
});

export const getHomeSliders = () => (dispatch) => {
    sliderService.getHomeSlider((response) => {
        dispatch(getHomeSlidersAction(response));
    });
};

export const getHomeSliderByIdAction = (homeSlidersData) => ({
  type: actionTypes.GET_HOME_SLIDER_BY_ID,
  payload: homeSlidersData,
});

export const getHomeSliderById = (id, onSuccess) => (dispatch) => {
  sliderService.getHomeSliderById(id, (response) => {
      dispatch(getHomeSliderByIdAction(response));
      onSuccess(response);
  });
};
