import React from 'react'
import ReactDOM from 'react-dom/client'

import { Provider } from 'react-redux'
import store from './store'

import App from './modules/app/components/App';

import { getCookieAnalytics } from './modules/common/utils/cookiesUtils';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-W32M2G6'
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store()}>
    <App />
  </Provider>
)

if(getCookieAnalytics() && getCookieAnalytics()!="false"){
  TagManager.initialize(tagManagerArgs);
  //ReactGA.initialize('G-WGNRC0NQ4R');
}