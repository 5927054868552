import {combineReducers} from 'redux';
import * as actionTypes from "./actionTypes";

const initialState = {
  tokenRecaptcha: null
};

const tokenRecaptcha = (state = initialState.tokenRecaptcha, action) => {
  switch(action.type) {
    case actionTypes.GET_TOKEN_RECAPTCHA: return action.tokenRecaptcha;
    default: return state;
  }
}

const reducer = combineReducers({
  tokenRecaptcha
});

export default reducer;
