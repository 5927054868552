import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";


const initialPromotion = {
    id: null,
    nombre: null,
    imagen: null,
    slug: null,
};


const initialState = {
    allHeaders: [],
    header: initialPromotion
}

const allHeaders = (state = initialState.allHeaders, action) => {
    switch (action.type) {
        case actionTypes.GET_HEADERS:
            return action.headers;
        default:
            return state;
    }
};

const headerBySlug = (state = initialState.header, action) => {
    switch (action.type) {
        case actionTypes.GET_HEADERS_BY_SLUG:
            return action.header;
        default:
            return state;
    }
};

const reducer = combineReducers({
    allHeaders,
    headerBySlug
});

export default reducer;