import {combineReducers} from 'redux';
import * as actionTypes from './actionTypes';

const initialStateCookies = {
  marketingCookiesList: [],
  preferencesCookiesList: [],
  mandatoryCookiesList: [
    {
      name: "comunity",
      duration: "cookies.mandatory.duration",
      description: "cookies.mandatory.description1"
    },
    {
      name: "cookielawinfo-checkbox-necessary",
      duration: "cookies.mandatory.duration",
      description: "cookies.mandatory.description1"
    },
    {
      name: "cookielawinfo-checkbox-non-necessary ",
      duration: "cookies.mandatory.duration",
      description: "cookies.mandatory.description2"
    },
    {
      name: "CookieLawInfoConsent",
      duration: "cookies.mandatory.duration",
      description: "cookies.mandatory.description3"
    },
    {
      name: "viewed_cookie_policy",
      duration: "cookies.mandatory.duration",
      description: "cookies.mandatory.description4"
    }
  ],
  analyticsCookiesList: [
    {
      name: "_ga",
      duration: "cookies.analytics.duration1",
      description: "cookies.analytics.description1"
    },
    {
      name: "_gat",
      duration: "cookies.analytics.duration2",
      description: "cookies.analytics.description2"
    },
    {
      name: "_gid",
      duration: "cookies.analytics.duration3",
      description: "cookies.analytics.description1"
    },
    {
      name: "_dc_gtm",
      duration: "cookies.analytics.duration4",
      description: "cookies.analytics.description1"
    }
  ]
};

const initialStateModal = {
  modal: false,  
}

const mandatoryCookiesList = (state = initialStateCookies.mandatoryCookiesList, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const analyticsCookiesList = (state = initialStateCookies.analyticsCookiesList, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const preferencesCookiesList = (state = initialStateCookies.preferencesCookiesList, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const marketingCookiesList = (state = initialStateCookies.marketingCookiesList, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const modal = (state = initialStateModal, action) => {
  switch (action.type) {
      case actionTypes.COOKIES_CONSENT:
        return { ...state, [Object.keys(action.consent)[0]]: action.consent[Object.keys(action.consent)[0]] }
      default:
        return initialStateModal;
  }
}

const reducer = combineReducers({
  mandatoryCookiesList,
  analyticsCookiesList,
  preferencesCookiesList,
  marketingCookiesList,
  modal
});

export default reducer;
