import * as actionTypes from "./actionTypes";
import * as mapService from "../../api/services/mapService";
import { getCookieIdLogin, setCookieMapUUID } from "../common/utils/cookiesUtils";

export const getMapCompleted = (data) => ({
    type: actionTypes.GET_MAP,
    map: data,
});

export const insertCurrentSessionHourCompleted = (data) => ({
    type: actionTypes.INSERT_SESSION,
    session: data,
});

export const clearMapCompleted = (data) => ({
    type: actionTypes.GET_MAP,
    map: data
});

export const clearSummaryCompleted = (data) => ({
    type: actionTypes.GET_SUMMARY,
    summary: data
});

export const getSummaryCompleted = (data) => ({
    type: actionTypes.GET_SUMMARY,
    summary: data,
});

export const getMap = (cinema, uuid, session, changeNotNumeredValue, onSuccess) => (dispatch) => {
    mapService.getMap(cinema, uuid, session, (response) => {
        if(response != null){
            setCookieMapUUID(response.uuid, 1);
            dispatch(getMapCompleted(response));
            changeNotNumeredValue(0);
        }else{
            mapService.getUUID(cinema, (response) => {
                setCookieMapUUID(response.UUID, 1);
                onSuccess(null);
            })
        }
    });
};

export const insertCurrentSessionHour = (session) => (dispatch) => {
    dispatch(insertCurrentSessionHourCompleted(session));
}

export const clearMap = () => (dispatch) => {
    const emptyMap = {
        rows: 0,
        columns: 0,
        uuid: "",
        segcadses: "599",
        plano: []
    };

    dispatch(clearMapCompleted(emptyMap));
};

export const clearSummary = () => (dispatch) => {
    const emptySummary = "";
    dispatch(clearSummaryCompleted(emptySummary));
};

export const selectSeat = (formData, onSuccess, onError) => () => {
    mapService.selectSeat(formData, 
        (response) => {
            onSuccess(response);
        }, 
        (response) => {onError(response)}
    );
};

export const getSummary = (formData, onSuccess, onError) => (dispatch) => {
    mapService.getSummary(formData, 
        (response) => {
            dispatch(getSummaryCompleted(response));
            function returnResponse() {
                onSuccess(response)
            }
            setTimeout(returnResponse, 200);
        },
        (response) => {onError(response)}
    );
};

export const getEntradas = (cinema, uuid, onSuccess) => (dispatch) => {
    mapService.getEntradas(cinema, uuid, (response) => {
        response.Ok == 1 ? onSuccess(true) : onSuccess(false);
    });
};

export const applyCode = (formData, onSuccess, onError) => () => {
    mapService.applyCode(formData,
        (response) => {
            onSuccess(response);
        },
        (response) => {onError(response)}

    );
}

export const payTicket = (url, formData, onSuccess, onError) => () => {
    mapService.payTicket(url, formData,
        (response) => {
            onSuccess(response);
        },
        (response) => {onError(response)}

    );
}
