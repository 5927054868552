import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

const initialState = {
    homeSliders: [{
        id: null,
        idPelicula: null,
        titulo: null,
        fecha: null,
        descripcion: null,
        tipo_slider: null,
        trailer: null,
        imagen: null,
        imagenMovil: null,
        reproductor: null,
        orden: null,
    }],
    homeSlider: {
        id: null,
        idPelicula: null,
        titulo: null,
        fecha: null,
        descripcion: null,
        tipo_slider: null,
        trailer: null,
        imagen: null,
        imagenMovil: null,
        reproductor: null,
        orden: null,
    }
};

const homeSliders = (state = initialState.homeSliders, action) => {
    switch (action.type) {
        case actionTypes.GET_HOME_SLIDERS:
            return action.payload;
        default:
            return state;
    }
};

const homeSliderById = (state = initialState.homeSlider, action) => {
    switch (action.type) {
        case actionTypes.GET_HOME_SLIDER_BY_ID:
            return action.payload;
        default:
            return state;
    }
};

const reducer = combineReducers({
    homeSliders,
    homeSliderById
});

export default reducer;
