import { config, appFetch, appFetchFile } from "../appFetch";

export const sendReservaColegios = (data, onSuccess, onError) => appFetch(
  'https://api.galicine.com.es/api/contacto/function::sendReservaColegios',
  config('POST', data), 
  onSuccess, onError);

export const sendTrabajaConNosotros = (data, onSuccess, onError) => appFetchFile(
    'https://api.galicine.com.es/api/contacto/function::sendTrabajaConNosotros',
    data, 
    onSuccess, onError);

export const sendMailContactos = (data, onSuccess, onError) => appFetch(
    'https://api.galicine.com.es/api/contacto/function::sendMailContacto',
    config('POST', data), 
    onSuccess, onError);