import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

const initialBlog = {
    id: null,
    idCategoria: null,
    titulo: null,
    entradilla: null,
    cuerpo: null,
    fechaAlta: null,
    fechaModificacion: null,
    imagenDestacada: null,
    slug: null
};


const initialState = {
    allBlogs: [],
    recentBlogs: [],
    blog: initialBlog,
    allBlogsPaged: [],
    blogBySlug: []
}

const allBlogs = (state = initialState.allBlogs, action) => {
    switch (action.type) {
        case actionTypes.GET_BLOGS:
            return action.blogs;
        default:
            return state;
    }
};

const recentBlogs = (state = initialState.recentBlogs, action) => {
    switch (action.type) {
        case actionTypes.GET_RECENT_BLOGS:
            return action.blogs;
        default:
            return state;
    }
};

const allBlogsPaged = (state = initialState.allBlogsPaged, action) => {
    switch (action.type) {
        case actionTypes.GET_BLOGS_PAGED:
            return action.blogs;
        default:
            return state;
    }
};

const blogById = (state = initialState.blog, action) => {
    switch (action.type) {
        case actionTypes.GET_BLOG_BY_ID:
            return action.blog;
        default:
            return state;
    }
};

const blogBySlug = (state = initialState.blogBySlug, action) => {
    switch (action.type) {
        case actionTypes.GET_BLOG_BY_SLUG:
            return action.blog;
        default:
            return state;
    }
};

const allCategoryBlogs = (state = initialState.allBlogs, action) => {
    switch (action.type) {
        case actionTypes.GET_CATEGORY_BLOGS:
            return action.categoryBlogs;
        default:
            return state;
    }
};

const categoryBlogById = (state = initialState.blog, action) => {
    switch (action.type) {
        case actionTypes.GET_CATEGORY_BLOG_BY_ID:
            return action.categoryBlog;
        default:
            return state;
    }
};

const reducer = combineReducers({
    allBlogs,
    recentBlogs,
    allBlogsPaged,
    blogById,
    blogBySlug,
    allCategoryBlogs,
    categoryBlogById
});

export default reducer;