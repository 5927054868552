import { config, appFetch } from "../appFetch";

const recintos = {
    9: {url: "multicinesbergantinos", key: "CPyAmYlwv4xKCpeHqD3Y"},
    11: {url: "multicinesgranarousa", key: "CPyAmYlwv4xKCpeHqD3Y"},
    13: {url: "multicineshollywood", key: "CPyAmYlwv4xKCpeHqD3Y"},
    14: {url: "multicinesbarbanza", key: "CPyAmYlwv4xKCpeHqD3Y"},
    19: {url: "cinesplazaeliptica", key: "CPyAmYlwv4xKCpeHqD3Y"},
    48: {url: "cinexpo", key: "CPyAmYlwv4xKCpeHqD3Y"},
    52: {url: "cinepontevella", key: "CPyAmYlwv4xKCpeHqD3Y"},
}

export const getMap = (cinema, uuid, session, onSuccess) => appFetch(`https://api.galicine.com.es/api/pelicula/function::getPlano||cine::${cinema}||uuid::${uuid}||sesion::${session}`, config("GET"), onSuccess);
export const selectSeat = (formData, onSuccess, onError) => appFetch(`https://api.galicine.com.es/api/pelicula/function::selButaca`, config('POST', formData), onSuccess, onError);
export const getSummary = (formData, onSuccess, onError) => appFetch(`https://api.galicine.com.es/api/compra/function::resumen`, config('POST', formData), onSuccess, onError);

export const applyCode = (formData, onSuccess, onError) => appFetch(`https://api.galicine.com.es/api/compra/function::aplicodpro`, config('POST', formData), onSuccess, onError);
export const payTicket = (url, formData, onSuccess, onError) => appFetch(url, config('POST', formData), onSuccess, onError);

export const getUUID = (recinto, onSuccess, onError) => appFetch(`https://`+recintos[recinto].url+`.reservaentradas.com/`+recintos[recinto].url+`?recinto=`+recinto+`&key=`+recintos[recinto].key, config('POST'), onSuccess, onError);

export const getEntradas = (uuid, cinema, onSuccess) => appFetch(`https://api.galicine.com.es/api/compra/getEntradas::${uuid}||cine::${cinema}`, config("GET"), onSuccess);