import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

const initialMap = {
    rows: 0,
    columns: 0,
    uuid: "",
    segcadses: "599",
    plano: []
};

const initialSession = {
    id: null,
    date: null,
    hour: null,
    numbered: null,
    room: null,
    capacity: null,
    blindfold: 0,
    wheelchair: 0

};

const initialState = {
    map: initialMap,
    session: initialSession,
    summary: ""
}

const map = (state = initialState.map, action) => {
    switch (action.type) {
        case actionTypes.GET_MAP:
            return action.map;
        default:
            return state;
    }
};

const summary = (state = initialState.summary, action) => {
    switch (action.type) {
        case actionTypes.GET_SUMMARY:
            return action.summary;
        default:
            return state;
    }
};

const session = (state = initialState.session, action) => {
    switch (action.type) {
        case actionTypes.INSERT_SESSION:
            return action.session;
        default:
            return state;
    }
};

const reducer = combineReducers({
    map,
    session,
    summary
});

export default reducer;