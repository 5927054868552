import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";


const initialPromotion = {
    id: null,
    titulo: null,
    enlace: null,
    imagen: null,
    condiciones: null,
    orden: null,
    estado: null,
    cines: []
};


const initialState = {
    allPromotions: [],
    activePromotions: [],
    promotion: initialPromotion
}

const allPromotions = (state = initialState.allPromotions, action) => {
    switch (action.type) {
        case actionTypes.GET_PROMOTIONS:
            return action.promotions;
        default:
            return state;
    }
};

const activePromotions = (state = initialState.activePromotions, action) => {
    switch (action.type) {
        case actionTypes.GET_ACTIVE_PROMOTIONS:
            return action.promotions;
        default:
            return state;
    }
};

const promotion = (state = initialState.promotion, action) => {
    switch (action.type) {
        case actionTypes.GET_PROMOTION_BY_SLUG:
        case actionTypes.GET_PROMOTION_BY_ID:
            return action.promotion;
        default:
            return state;
    }
};

const reducer = combineReducers({
    allPromotions,
    activePromotions,
    promotion
});

export default reducer;