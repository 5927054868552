import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";


const initialState = {
    data: {
        Nombre: null,
        Apellidos: null,
        Email: null,
        Direccion: null,
        Poblacion: null,
        CodPostal: null,
        FechaNac: null,
        Telefono: null,
        DNI: null
    },

    points: {
        PuntosDisponibles: null,
        FechaCaducidadPuntos: null,
        Movimientos: []
    },

    tickets: {
        active: null,
        all: null,
    },

    subscriptions: {
        Suscripciones: []
    },

    pinStatus: {
        Ok: null,
        Error: null
    }
}

const data = (state = initialState.data, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_DATA:
            return action.data;
        default:
            return state;
    }
};

const points = (state = initialState.points, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_POINTS:
            return action.data;
        default:
            return state;
    }
};

const tickets = (state = initialState.tickets, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_TICKETS:
            return action.data;
        default:
            return state;
    }
}

const subscriptions = (state = initialState.subscriptions, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_SUBSCRIPTIONS:
            return action.data;
        default:
            return state;
    }
}

const pinStatus = (state = initialState.pinStatus, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_USER_PIN:
            return action.data;
        default:
            return state;
    }
}

const reducer = combineReducers({
    data,
    points,
    tickets,
    subscriptions,
    pinStatus
});

export default reducer;